import React from 'react';
import {Container, Grid} from '@material-ui/core';

import styles from './footer.module.scss';

const Footer = () => {
  return (
      <footer className={styles.footerWrapper}>
        <Container
            maxWidth="lg"
            className={styles.footerWrapperInner}
        >
          <Grid
              container
              component="div"
              direction="column"
              justify="center"
              className={styles.footerWrapperInnerGrid}
          >
            <p className={styles.footerWrapperText}>Országos Széchényi Könyvtár – SZTE Klebelsberg Könyvtár</p>
          </Grid>
        </Container>
      </footer>
  )
}

export default Footer