import React from 'react';
import { Link } from "gatsby"

import {
    Container,
    Grid,
    Box,
    Breadcrumbs
} from '@material-ui/core';

import styles from '../pages/index.module.scss';

const BreadcrumbsLayout = props => {

    const output = [];
    for (let i = 0; i < props.menuItems.length; i++) {
        if (i === props.menuItems.length - 1) {
            output.push(<span className={styles.breadcrumbMenuNow} key={props.menuItems[i].name}>{props.menuItems[i].name}</span>)
            break;
        }
        output.push(<Link to={props.menuItems[i].path} className={styles.breadcrumbMenuLink} key={props.menuItems[i].name}>{props.menuItems[i].name}</Link>);
    }

    return (
        <Grid
            className={styles.mainContainer}
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
        >
            <Box className={styles.topContainerSubpage} component="section">
            </Box>
            <Box className={[styles.pageContentContainerSubpage, styles.pageContentContainerSubpageWhite].join(' ')} component="section">
                <Container maxWidth={"lg"}>
                    <Box className={styles.breadcrumbContainerSubpage} component="div">
                        <Breadcrumbs separator="/" aria-label="breadcrumb">
                            {output}
                        </Breadcrumbs>
                    </Box>
                    {props.children}
                </Container>
            </Box>
        </Grid>
    )
}

export default BreadcrumbsLayout;
