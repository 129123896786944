import React,{useContext,useState} from "react"
import { Link, navigate } from "gatsby"

import {
  Grid,
  InputBase,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { SearchContextDispatch } from '../context/search-context/search-context'
import * as actionTypes from '../context/actionTypes'

import searchStyles from './searchbar.module.scss';

const SearchBar = () => {
  const dispatch = useContext(SearchContextDispatch) 

  const [localKeyword,setLocalKeyword] = useState('') 

  const onKeyUpHandler = e => {
      if(e.keyCode !== 13) return
      
      return onSearchButtonClickedHandler()
  }

  const onSearchButtonClickedHandler = () => {
    if(localKeyword.trim() !== ''){
      dispatch({
        type: actionTypes.ON_SET_KEYWORD_FOR_BASIC_SEARCH,
        keyword: localKeyword
      })

      return navigate(`/element_list?keyword=${localKeyword}`)
    }
    
    dispatch({
      type: actionTypes.ON_EMPTY_SEARCH
    })

    return navigate(`/element_list?keyword=${localKeyword}`)
  }

  return (
    <Grid
        container
        className={searchStyles.searchLine}
        spacing={0}
    >
      <Grid
          item
          className={searchStyles.search}
          sm={9}
          xs={12}
      >
        <Grid
            container
            spacing={0}
            className={searchStyles.maxHeightContainer}
        >
          <div className={searchStyles.searchIcon}>
            <SearchIcon/>
          </div>
          <InputBase
              placeholder="Keresés…"
              classes={{
                root: searchStyles.inputRoot,
                input: searchStyles.inputInput,
              }}
              inputProps={{'aria-label': 'search'}}
              value={localKeyword}
              onChange={e => setLocalKeyword(e.target.value)}
              onKeyUp={onKeyUpHandler}
          />
          <Link
            onClick={onSearchButtonClickedHandler}
            to={`/element_list?keyword=${localKeyword}`}
            className={searchStyles.searchButton}>Keresés</Link>
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid
            container
            spacing={0}
            className={searchStyles.maxHeightContainer}
        >
          <Link to="/advanced-search" className={searchStyles.searchButtonExtended}>
            Részletes keresés
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchBar
