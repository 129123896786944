import React from "react"
import { Link } from "gatsby"
import {
  Container,
  Grid,

} from "@material-ui/core"

import SearchBar from "../components/searchbar"
import NavigationBar from "./Menubar/mainmenu"

import styles from "./header.module.scss"

const HeaderWsb = () => {
  return (
      <header className={styles.headerContainer}>
        <Container maxWidth="lg">
          <Grid container component="div" className={styles.headerWrapper} spacing={0} alignItems="center">
            <Grid
                item
                component="div"
                md={3}
                className={styles.logoSpace}
            >
              <Link to="/">
                <div className={styles.mkdnyLogo} />
              </Link>
            </Grid>
            <Grid
                item
                component="div"
                md={9}
                className={styles.menuSpace}
            >
              <div className={styles.searchBarWrapper}>
                <SearchBar/>
              </div>
              <NavigationBar/>
            </Grid>
          </Grid>
        </Container>
      </header>
  )
}

export default HeaderWsb
