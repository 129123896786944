import React, { useState, useContext } from 'react';

//CSS
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Snackbar
} from '@material-ui/core'
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import MenuIcon from '@material-ui/icons/MenuSharp';
import styles from "./mainmenu.module.scss"

//COMPONENTS
import SearchBar from "../searchbar"
import ErrorSnackbar from '../ErrorSnackbar/ErrorSnackbar';

//CONTEXT
import {
  AuthContextState,
  AuthContextDispatch,
} from "../../context/auth-context/auth-context"
import * as actionTypes from "../../context/actionTypes"
import { setAccessToken } from "../Auth/accessToken"

//GATSBY
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from "gatsby"

//UTILS
import sort from "fast-sort"

const NavigationBar = () => {
  const menuItemsData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { staticPage: { eq: true } } }) {
        nodes {
          frontmatter {
            title
            isActive
            order
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  const state = useContext(AuthContextState)
  const dispatch = useContext(AuthContextDispatch)

  const [accountProprieties, setAccountProprietes] = useState({
    left: 0,
    top: 0,
    show: false,
  })

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const mouseLeaveAccountIconHandler = () => {
    setAccountProprietes({
      ...accountProprieties,
      show: false,
    })
  }

  const logOffHandler = () => {
    setAccessToken("")
    dispatch({
      type: actionTypes.REMOVE_ACCESS_TOKEN,
    })
  }

  const openSnackbarModalHandler = () => {
    setOpenSnackbar(true);
  }

  const closeErrorSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  }

  let menuItems = [
    <li className={styles.listItem} key="bejelentes">
      <Link
        to="/bejelentes"
        className={styles.listItemLink}
        activeClassName={styles.listItemLinkActive}
      >
        Bejelentés
      </Link>
    </li>
  ]

  const generatedMenuItems = sort(menuItemsData.allMarkdownRemark.nodes).asc(node => node.order).map(node => {
    let returnValue = (
      <li className={styles.listItem} key={node.frontmatter.title}>
        <Link
          to={`/${node.fields.slug}`}
          className={styles.listItemLink}
          activeClassName={styles.listItemLinkActive}
        >
          {node.frontmatter.title}
        </Link>
      </li>
    )

    if (!node.frontmatter.isActive) {
      returnValue = (
        <li className={styles.listItem} key={node.frontmatter.title}>
          <div
            className={styles.listItemLink}
            style={{ cursor: 'pointer' }}
            onClick={openSnackbarModalHandler}
          >
            {node.frontmatter.title}
          </div>
        </li>
      )
    }

    return returnValue;
  })

  menuItems.push(generatedMenuItems)
  menuItems.push(
    <li className={styles.listItem} key="visualisation">
      <Link
        to="/visualisation"
        className={styles.listItemLink}
        activeClassName={styles.listItemLinkActive}
      >
        Vizualizáció
      </Link>
    </li>
  )

  if (state.accessToken.trim() !== "") {
    menuItems.push(
      <ExpansionPanel className={styles.accountIconContainer} onMouseLeave={mouseLeaveAccountIconHandler} key='profilIcon'>
        <ExpansionPanelSummary className={styles.accountIconContainerSum}>
          <AccountCircleSharpIcon
            key='accIcon'
            className={styles.accountIcon}
            fontSize='large'
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.accountIconContainerDet}>
          <div className={styles.accountIconModalMenuItem} onClick={logOffHandler}>Kijelentkezés</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }



  return (
    <React.Fragment>
      <nav className={styles.navigationBar}>
        <ul className={styles.list}>
          {menuItems}
        </ul>
        <div className={styles.mobileMenuList}>
          <ExpansionPanel
            className={styles.mobileMenuListContainer}
            square={false}
          >
            <ExpansionPanelSummary expandIcon={<MenuIcon />} className={styles.mobileMenuListSum}>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={styles.mobileMenuListDet}>
              <ul className={styles.mobileMenuList}>
                {menuItems}
              </ul>
              <SearchBar />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

      </nav>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={closeErrorSnackbarHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorSnackbarHandler}
          variant="warning"
          message={"Hamarosan"}
        />
      </Snackbar>
    </React.Fragment>
  )
}

export default NavigationBar
