import React from 'react';
import { Grid } from '@material-ui/core';

import HeaderWsb from '../components/header_wsb';
import Footer from '../components/footer';

import '../styles/basic_style.scss';

const SubPageLayout = (props) => {
  return (

      <Grid
          container component="div"
          direction="column"
          justify="space-between"
          className="siteBasicContainer"
      >
        <HeaderWsb/>
          {props.children}
        <Footer/>
      </Grid>
  )
}

export default SubPageLayout